import React from "react";

const NoPage = () => {
  return (
    <div className="no-page">
      Page cannot be found...might be developed soon :)
    </div>
  );
};

export default NoPage;
